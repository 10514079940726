<template>
    <div class="BigBoxTwo">
        <div class="ConBox">
            <div class="MyPingJia">我的评价</div>
            <div class="Con">
                <div class="TiTle">
                    <div class="ShopInformation">商品信息</div>
                    <div class="OrderID">订单号</div>
                    <div class="MyEvaluate">我的评价</div>
                </div>
                <div style="width:100%;overflow-y: auto;" @mousewheel="MyAaScroll($event)" ref="personDom">
                    <div class="OrderInformationBox" v-for="(item, index) in MyList" :key="index">
                        <div class="OrderInformation">
                            <img :src="item.logoUrl" alt="">
                            <div class="TitleTime">
                                <div class="ShopTitle">{{ item.title }}</div>
                                <div style="color: rgba(48, 48, 48, 1);">{{ item.createTime }}</div>
                            </div>
                            <div class="OrderNumber">{{ item.orderNo }}</div>
                            <div class="EvaluationGrade">
                                <img :src="item.commentLevel == 1 ? url1 : item.commentLevel == 2 ? url2 : item.commentLevel == 3 ? url3 : ''" alt="">
                            </div>
                            <div class="Comment">{{ item.commentContent }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="MyList.length==0" style="height: 50vh; display: flex;flex-direction: column; align-items: center;justify-content: center;">
                    <img src="../../../assets/PersonalCenter/ZWSJ.png" alt="" style="width:20vh;height: 20vh;">
                    <div style="margin-top: 2vh;">暂无数据</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { log } from 'console';
export default {
    data () {
        return {
            MyList: [],
            total: null,
            url1: require('../../../assets/CulturalCreation/evaluate/good.png'),
            url2: require('../../../assets/CulturalCreation/evaluate/ordinary.png'),
            url3: require('../../../assets/CulturalCreation/evaluate/bad.png'),
            Form: {
                // 每页条数
                pageSize: 4,
                // 页码
                pageNum: 1,
            },
        }
    },
    created () {
        this.GetMyCommentList()
    },
    mounted () {
        //商品列表
        this.$refs.personDom.addEventListener('scroll', this.MyAaScroll, true);
    },
    destroyed () {
        this.$refs.PersonDomOne.addEventListener('scroll', this.MyAaScroll, true);
        //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题 
    },
    methods: {
        GetMyCommentList () {
            var axios = require("axios");
            const data = {
                pageSize: this.Form.pageSize,
                pageNum: this.Form.pageNum,
            };
            var config = {
                method: "post",
                url: this.$Schttp + "/vtp/app/orderComment/page",
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config).then((res) => {
                if (res.data.code == 200) {
                    const comm = res.data.rows;
                    this.MyList.push(...comm);
                    this.total = res.data.total;
                }
            });
        },
        MyAaScroll (e) {
            let h = e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight
            if (h > -4) {

                if (this.Form.pageNum * this.Form.pageSize >= this.total) {
                } else {
                    this.Form.pageNum++
                    this.GetMyCommentList()
                }
            }
        },
    }
}
</script>

<style lang="less" scoped>
.BigBoxTwo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 2vw;
    .ConBox {
        width: 100%;
        height: 100%;

        .MyPingJia {
            font-size: 2vw;
        }

        .Con {
            width: 100%;
            height: 80%;
            margin: 0 auto;

            .SouSBox {
                width: 35vw;
                height: 5vh;
                border: 2px solid rgb(153, 153, 153);
                display: flex;
                align-items: center;

                img {
                    height: 50%;
                    margin: 0 1.5vh;
                }

                input {
                    border: none;
                    outline: none;
                    height: 100%;
                    width: 90%;
                    font-size: 2vh;
                    background-color: rgba(255, 255, 255, 0);
                    color: rgba(173, 173, 173, 1);
                    text-indent: 0.5vw;
                }
            }

            .TiTle {
                // font-size: 2vh;
                font-size: 1vw;
                height: 6vh;
                margin: 2vh 0 2vh 0;
                display: flex;
                align-items: center;
                background-color: #64a2ff;

                .ShopInformation {
                    width: 30%;
                    display: flex;
                    justify-content: center;
                    color: #ffffff;
                }

                .OrderID {
                    width: 20%;
                    display: flex;
                    justify-content: center;
                    overflow: hidden;
                    color: #ffffff;
                }

                .MyEvaluate {
                    width: 40%;
                    display: flex;
                    justify-content: center;
                    color: #ffffff;
                }
            }

            .OrderInformationBox {
                margin: 1.5vh 0;
                font-size: 2vh;
                height: 15vh;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(41, 98, 182, 0.3);

                .OrderInformation {
                    width: 95%;
                    height: 75%;
                    display: flex;

                    img {
                        height: 100%;
                        width: 18vh;
                    }
                }

                .TitleTime {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin: 0 2vh;
                    width: 15%;

                    .ShopTitle {
                        display: -webkit-box; //将元素设为盒子伸缩模型显示
                        -webkit-box-orient: vertical; //伸缩方向设为垂直方向
                        -webkit-line-clamp: 1; //超出3行隐藏，并显示省略号
                        overflow: hidden;
                        color: #ffffff;
                    }
                }

                .OrderNumber {
                    width: 20%;
                    color: #ffffff;
                }

                .EvaluationGrade {
                    width: 14%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-size: 100% 100%;

                    img {
                        height: 3vh;
                        width: 3vh;
                    }
                }

                .Comment {
                    height: 95%;
                    width: 32%;
                    display: -webkit-box; //将元素设为盒子伸缩模型显示
                    -webkit-box-orient: vertical; //伸缩方向设为垂直方向
                    -webkit-line-clamp: 4; //超出3行隐藏，并显示省略号
                    overflow: hidden;
                    color: #ffffff;
                }
            }
        }
    }

    /* 整个滚动条 */
    ::-webkit-scrollbar {
        width: 0.3vw;
        height: 0.1vh;
        background-color: #64a2ff;
    }

    /* 滚动条上的滚动滑块 */
    ::-webkit-scrollbar-thumb {
        height: 0.3vh;
        background-color: #0f1755;
        border-radius: 32px;
    }
}
</style>